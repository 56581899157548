@import 'src/styles/mixins';
@import 'src/styles/variables';

.button {
  font-size: 16px;
  font-weight: 500;
  padding: 16px 24px;
  color: $color-dark;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  border: none;
  border-radius: 4px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  background-color: $color-gray0;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px $color-white, 0 0 2px 7px $color-gray0;
  }

  &:hover {
    background-color: $color-gray0Hover;
  }

  &.action {
    background-color: $color-attention;

    &:focus {
      box-shadow: 0 0 0 5px $color-white, 0 0 2px 7px $color-attention;
    }

    &:hover {
      background-color: $color-attentionHover;
    }
  }

  :disabled {
    background-color: $color-lightGrey;
    cursor: not-allowed;
  }
}

.buttonLink {
  display: inline-block;
  text-decoration: none;
  color: $color-dark;
  padding: 15px 30px 16px;
  border-radius: 4px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 500;
  background-color: $color-gray0;
  transition: background-color 0.15s ease-in-out;

  &:hover {
    background-color: $color-gray0Hover;
  }

  &:focus {
    box-shadow: 0px 0px 2px 4px $color-green;
    outline: none;
  }

  &.action {
    background-color: $color-attention;
    font-family: var(--poppins-font);
    &:hover {
      background-color: $color-attentionHover;
    }
  }

  &.narow {
    padding: 10px 20px 12px;
  }
}

.sending {
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    margin-right: 20px;
  }
}
